/* To import the colors of the website */
@import url("../utility/colours.css");


.resume-main{
     background-color: var(--primary);
     min-height: max-content;
     /* overflow-y: scroll !important; */
}
.resume-head{
     margin-bottom: 2em !important;
     font-weight: bold;
     color: var(--contrast);
     font-size: 2em !important;   
}
.MuiLinearProgress-colorPrimary{
     color: var(--primary);
}
.resume-container{
     min-height: 60vh;
     background-color: var(--altacc);
     margin: 1em auto;
     width: max-content;
     max-width: 80%;
     border-radius: 10px;
     padding: 1.5em;
     overflow-x: scroll;
}
.mobile-resume-container{
     height: max-content;
     min-height: 80vh;
     overflow: hidden;
}
.resume-load-cont{
     color: var(--primary) !important;
}
.resume-pdf{
     width: 100% !important;
     overflow: hidden !important;
}
.resume-dowload{
     border-color: var(--contrast) !important;
     color: var(--contrast) !important;
     font-weight: bold !important;
     font-family: Quicksand !important;
     /* padding: .2em .5em !important; */
     border-radius: 5px !important;
     margin: 2em auto !important;
}

.resume-dowload:hover{
     background-color: var(--contrast) !important;
     color: var(--primary) !important;
}
.resume-back:hover ,.resume-next:hover{
     color: var(--secondary) ;
     cursor:pointer ;
}            
.resume-dowload-link{
     text-decoration: none;
     color: inherit;
}
/* Footer CSS */
#footer-resume{
     color: var(--contrast);
}