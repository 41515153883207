/* To import the colors of the website */
@import url("../utility/colours.css");

.nav{
    text-align: center;
    font-family: Quicksand !important;
    background-color: var(--primary);
}
.nav-appBar{
  background-color: #112233de !important;
  backdrop-filter: saturate(100%) blur(4px) !important;
}

.nav-toolbar{
  color: var(--accent) !important;
  font-size: medium !important;
}

.nav-drawer{
  backdrop-filter: saturate(100%) blur(3px) !important;
}
.nav-drawer-div{
  background-color: var(--primary) !important;
  opacity: .9;
  height: 100vh;
}

/* Link styles on the header */
.nav-head-text{
  text-decoration: none;
  color: inherit;
  text-align: left;
}

.nav-links{
  text-decoration: none;
  text-transform: capitalize;
  margin: auto 1em;
  color: var(--accent) ;
  font-family: Quicksand;
  font-size: large;
  display: flex;
  align-content: center;
  justify-content: first baseline;
}
.nav-links:hover{
  border-bottom: 2px solid var(--contrast);
}

.nav-links-active{
  color: var(--contrast);
}

.nav-link-contact{
  color: var(--contrast) !important;
  font-weight: bold !important;
  font-size: large;
  /* font-family: Quicksand ; */
  border: 2px solid var(--contrast) !important;
  border-radius: 5px !important;
  margin: auto;
}

/* Drawer styles */
.nav-drawer{
  color: var(--primary) !important;
}



@media only screen and (max-width: 600px) {
  .nav-links{
    margin: auto;
  }
}