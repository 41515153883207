/* Import the font used in the website */

body {
  margin: 0;
  /* font-family: Quicksand, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}


/* Styling to change the appearance of the scroll bars */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary);
  /* border-radius: 5px; */
}
/* The arrows at the beginning and end*/
/* ::-webkit-scrollbar-button{
  display: none;
} */
/* the track (progress bar) NOT covered by the handle. */
::-webkit-scrollbar-track-piece{
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
  background: var(--secondary);
  cursor: pointer;
}

