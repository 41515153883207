/* To import the colors of the website */
@import url("../utility/colours.css");

.home-cont{
  background-color: var(--primary);
  background-image: url("../images/home_bg_v2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  padding-top: 1px;
  overflow-x: hidden;
}

.App {
  text-align: center;
  background: transparent;
  color: white;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-img{
 
 background-color: var(--primary);
}
.home-main{
  height: max-content;
}
.home-main h2{
  /* margin-top: 40vh; */
  font-family: Quicksand !important;
  font-size: 4em !important;
}
.home_image{
  width: 200px;
  height: auto;
  margin: auto;
}
.scratch{
  position: absolute;
  margin-top: -28px; 
  margin-right: -20px;
}
.intro-text{
font-weight: 400;
font-size: 24px;
}
.home-tex-loop{
  color: var(--contrast) !important;
  font-weight: bold !important;
  font-size: 32px !important;
  margin: 2em auto !important;
}
.resume-button{
  color: var(--contrast) !important;
  text-decoration: none !important;
  font-family: montserrat !important;
  border: 2px solid var(--contrast) !important;
  border-radius: 5px !important;
}
.resume-button:hover{
  background-color: var(--contrast) !important;
  color: var(--primary) !important;
}
.text-contrast{
  color: var(--contrast);
}
.skills-section{
  padding-top: 2em !important;
  min-height: 50vh;
  background-color: var(--accent);
}

/* .skills-eulogy{
  color: var(--primary);
  font-size: 120% !important;
  margin: 2em auto !important;
} */
.profile-container{
  margin: 5em 0;
}
.profile-image-card1{
  width: 30em;
  height: 30em;
  transform: rotate(14deg);
  background-color: var(--secondary);
  border-radius: 10px;
}
.profile-image-card2{
  width: 30em;
  height: 30em;
  transform: rotate(-7deg);
  background-color: var(--contrast);
  border-radius: 10px;
}
.profile-image{
  /* display: block; */
  width: 30em;
  height: 30em;
  background-color: transparent;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-7deg);
}
.image{
  height: auto;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.skills-head{
  display: block !important;
  font-weight:bolder;
  font-size: xx-large;
  color: var(--primary);
}
.skill-container{
  margin: .6em auto !important;
}
.skill-name{
  background-color: var(--secondary);
  padding: 5px 0;
  border-radius: 5px;
  color: var(--accent);
  font-weight: bold;
}
.skill-name-alt{
  background-color: var(--contrast);
  color: var(--primary);
}
.skills-footer{
  display: block;
  font-weight:  bold;
}
.divider-container{
  display: block;
  margin: 5em auto !important;
}
.divider{
  height: auto;
  width: 80%;
}

/* Me Section */
/* .person-icon{
  height: auto;
  width: 100%;
} */

/* Technology section */
.tech-section{
  padding: 4em 1em;
  min-height: 100vh;
  background-color: var(--primary);
}
.tech-section h2{
  font-size: 36px;
  color: var(--accent);
}
.tech-section-container{
  margin-top: 4em;
}
.tech-section-container h2{
  font-size: 24px;
  color: var(--contrast);
}
.tech-section-icons{
  background: rgba(29, 207, 247, 0.1);
  border-radius: 20px;
  padding: 1em 1em;
}
/* Education Section */
.books-icon{
  height: auto;
  width: 50%;
}
/* Experience Section */
.worker-icon{
  height: auto;
  width: 100%;
}

/* Genenal Section */
.section{
  margin: 20% auto;
}
.profile-summary{
  text-align: left;
  margin: 1.5em .5em;
  font-size: large;
}
.card-container{
  padding: 1em 1em 1em 0;
  margin: 2em auto;
  min-width: 70%;
  max-width: 70%;
  width: max-content;
  height: max-content;
  border-radius: 10px;
  background: rgba(17, 34, 51);
}
.card-icon{
  margin: .5em auto 0 .5em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  padding: .5em;
  background-color: var(--contrast);
  color: rgba(17, 34, 51);
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-text{
  /* border: 2px solid red; */
  text-align: left;
}
.card-time{
  display: block;
  color: var(--contrast);
  font-weight: bold;
  margin: .5em auto;
}
.ongoing{
  font-size: small;
  background-color: var(--altacc);
  color: rgba(17, 34, 51, 0.56);
  padding: .1em .5em;
  border-radius: 10px;
}
.card-title{
  display: block;
  font-weight: bold;
  font-size: 24px;
  color: var(--accent);
  margin-bottom: .3em;
}
.card-org{
  display: block;
  font-weight: medium;
  font-size: 20px;
  color: var(--accent);
  margin-bottom: .5em;
}
.card-org a{
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.card-desc{
  color: var(--accent);
}
@media only screen and (max-width: 600px) {
  .bg{
    transform: rotate(90deg);
    width: 100%;
    height: 100%;
  }
  .tech-section-container{
    margin-top: 3em;
  }
  .home-tex-loop{
    font-size: 16px !important;
  }
  }
  .profile-image-card1{
    /* visibility: hidden; */
    width: 80%;
    margin: auto;
    height: 300px;
    /* transform: rotate(0); */
    margin-bottom: 6em;
  }
  .profile-image-card2{
    /* visibility: hidden; */
    width: 100%;
    height: 100%;
    /* transform: rotate(0); */
  }
  .profile-image{
    width: 100%;
    height: 100%;
    /* transform: rotate(0); */
  }
  .home_image{
    width: 150px;
  }
  .home-main h2{
    /* font-family: Quicksand !important; */
    font-size: 3em !important;
  }

  .skill-container{
    margin: 1em auto !important;
  }

  .divider{
    height: auto;
    width: 95%;
  }

  /* Experience Section */
  .worker-icon{
    display: none;
  }
  /* Education Section */
  .books-icon{
    display: none;
  }
  .card-title{
    font-size: 16px;
  }
  .card-org{
    font-size: 14px;
  }



