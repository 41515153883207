.footer{
     position: relative;
     /* bottom: 0; */
     /* margin: 6em auto; */
     text-align: center;
}

.footer-text{
     font-weight: bold;
     font-size: small;
}