/* Modal styles */
.contact-modal{
     position: fixed;
     z-index: 1300;
     right: 0;
     bottom: 0;
     top: 0;
     left: 0;
     display: flex;
     align-items: center;
     justify-content: center;
}

.contact-modal-box{
        background-color: var(--accent);
        border-radius: 15px;
        color: var(--secondary);
        padding: 0  15px 0 3em; /* top right botttom left */
        min-width: 40vw;
}
.backb-cont{
     margin-top: .5em ;
     font-size: medium !important;
     font-weight: bold ;
}
.backb{
     font-size: medium !important;
}
.contact-link-container{
     margin: 2em auto;
}
.contact-show-container{
     margin: 2em auto;
     margin-bottom: 1em;
     background-color: var(--secondary);
     color: var(--accent);
     border-radius: 10px;
     width: max-content;
     cursor: pointer;
}
.contact-show-container:hover{
     box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.contact-move{
     margin-left: 1em;
}

.contact-link-container a:hover{
     font-weight: bold;     
}
.contact-icon{
     color: var(--secondary);
     margin-right: 1em;
}
.contact-link{
     text-decoration: none;
     color: var(--secondary);
}
.social-icon-container{
     margin-top: 20% !important;
}

.social-icon{
     text-decoration: none;
     color: inherit;
}
.social-icon:hover{
     color: var(--primary) !important;
     
}
/* Form styles */
.contact-form{
     height: max-content;
     width: 100%;
     background-color: var(--white);
     border-radius: 15px;
     margin: 1em 0 !important;
     padding: 2em .2em;
}

.form-input{
     margin-bottom: 2em !important;
     border-color: var(--accent) !important;
}
.input-icon{
     color: var(--secondary) !important; 
     opacity: .6;
}
.form-send{
     background-color: var(--secondary) !important;
     font-family: Quicksand !important;
     font-size: small !important;
     width: max-content !important;
     color: var(--accent) !important;
     border-radius: 5px !important;
}

.form-send:hover{
     font-weight: bold !important;
     background-color: var(--accent) !important;
     color: var(--primary) !important;
}


@media only screen and (max-width: 700px) {
     .contact-modal-box{
          min-width: 70vw;
     }
     .contact-modal-box{
          padding: 0  6% 0 6%; /* top right botttom left */
  }
     .contact-form{
          padding: 1em 10px;
  }
  
}