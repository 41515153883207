/* To import the colors of the website */
@import url("../utility/colours.css");

.project-head{
     display: block;
     font-weight: bold;
     color: var(--contrast);
     font-size: 2em !important;
}
.proj-tag{
     color: var(--altacc);
     justify-self: center;
}

.tab-head{
     padding: 0 1em;
     width: max-content;
     margin: auto;
}
.cont-tablist{
     overflow-x: auto !important;
}
.project-tab{
     color: var(--altacc); 
     
}
.tab-active{
     font-family: Quicksand !important;
     color: var(--contrast) !important; 
     font: medium !important;
}
.tab-not-active{
     font-family: Quicksand !important;
     font-size: small !important;
     text-transform: uppercase;
}
/* Web design styles*/
.web-container{
     margin: 1em auto;
     text-align: left;
}
.web-img-container{
     width: 70%;
     height: 15em;
     max-height: max-content;
     color: var(--altacc);
     display: flex;
     justify-content: center;
     align-items: center;
}
.web-img{
     border-radius: 5px;
     width: 95%;
     height: auto;
}
.blue-img{
     border-radius: 5px;
     width: auto;
     height: 95%;
}
.web-title{
     color: var(--contrast);
     font-size: large;
     font-weight: bold;
     margin: .5em auto;
}
.web-title a{
     color: inherit;
     cursor: pointer;
     text-decoration: none !important;
}

.web-tool{
     display: inline-block;
     background-color: var(--secondary);
     padding: .2em .5em;
     border-radius: 6px;
     margin: 1em 1em .5em 0;
     font-size: small;
}
/* Project design tab */
.coming-soon{
     min-height: 60vh;
     font-size: x-large;
     display: flex;
     align-items: center;
     justify-content: center;
}

/* Graphics tab */
.graphics-container{
     margin: 1em auto;
     text-align: center;
}


.graphics-img-container{
     width: max-content;
     max-width: 100%;
     height: max-content;
     background-color: transparent;
     /* border-radius: 10px; */
     color: var(--altacc);
     display: flex;
     justify-content: center;
     align-items: center;
}
.graphics-img{
     border-radius: 10px;
     width: 65%;
     height: auto;
}

/* Footer CSS */
#footer-proj{
     color: var(--contrast);
}

@media only screen and (max-width: 600px) {
     .graphics-img{
          width: 90%;
          height: auto;
     }
     .web-img-container{
          width: 100%;
     }
     .web-container{
          width: 100%;
     }
}
