/* To import the colors of the website */
@import url("../utility/colours.css");

/* Project design tab */
.blog-coming-soon{
     min-height: 90vh;
     font-size: 4em;
     display: flex;
     align-items: center;
     justify-content: center;
     color: var(--contrast);
     font-weight: bold;
}

/* Footer CSS */
#footer-cert{
     color: var(--contrast);
}